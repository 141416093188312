import React from 'react';


import {HomeHeader} from '../components/PageHeader';
import UpgradeList from "./UpgradeList";
import * as upgradeService from "../services/UpgradeService";
import {UPGRADE_ICON} from "../Constants";
import * as upgradeStats from "../services/UpgradeStats";
import DataTableSavedFilters from "../components/DataTableSavedFilters";
import * as notifier from "../services/notifications";
import * as authService from "../services/AuthService";
import AnalysisWindow from "../components/AnalysisWindow";
import {Icon} from "../components/Icons";

export default class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user:authService.getSessionUser(this),
			selected: new Map(),
			isOpen: false
		};

		this.fetchData = this.fetchData.bind(this);
		this.filterHandler = this.filterHandler.bind(this);
		this.applySavedFilter = this.applySavedFilter.bind(this);
		this.selectionHandler = this.selectionHandler.bind(this);
		this.showSelectedHandler = this.showSelectedHandler.bind(this);
		this.purgeHandler = this.purgeHandler.bind(this);
		this.openAnalysisWindow = this.openAnalysisWindow.bind(this);
		this.closeAnalysisWindow = this.closeAnalysisWindow.bind(this);
		this.showStatsMenu = this.showStatsMenu.bind(this);
		this.getSumUpgradeResults = this.getSumUpgradeResults.bind(this);
		this.getAggregateUpgradeResults = this.getAggregateUpgradeResults.bind(this);
	}

	// Lifecycle
	render() {
		const {selected, filterColumns, user} = this.state;

		const actions = [
			<DataTableSavedFilters id="UpgradeList" key="UpgradeList" filterColumns={filterColumns}
								   onSelect={this.applySavedFilter}/>,
			<div className={`slds-dropdown-trigger slds-dropdown-trigger_click ${this.state.isOpen ? "slds-is-open" : ""}`}
				 id="analysisDropDown" onMouseEnter={this.showStatsMenu} onMouseLeave={this.showStatsMenu}>
				<button className="slds-button slds-button--neutral" id="btnAnalyze" disabled={selected.size === 0}>
					{"Stats "}
					<svg className="slds-button__icon">
						<use xmlnsXlink="http://www.w3.org/1999/xlink"
							 xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#metrics"/>
					</svg>
					<svg className="slds-button__icon slds-button__icon_x-small">
						<use xmlnsXlink="http://www.w3.org/1999/xlink"
							 xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down"/>
					</svg>
				</button>
				<div className="slds-dropdown slds-dropdown--left">
					<ul className="slds-dropdown__list" role="menu">
						<li className="slds-dropdown__item slds-border--bottom" role="presentation">
							<button className="slds-button slds-p-horizontal--x-small" value="Sum" type="button" onClick={this.getSumUpgradeResults}>
								Job results
							</button>
						</li>
						<li className="slds-dropdown__item slds-border--bottom" role="presentation">
							<button className="slds-button slds-p-horizontal--x-small" value="Aggregate" type="button" onClick={this.getAggregateUpgradeResults}>
								Org results
							</button>
						</li>
						<li className="slds-dropdown__item" role="presentation">
							<button className="slds-button slds-p-horizontal--x-small" value="chart" type="button" disabled={true}>
								Charts
							</button>
						</li>
					</ul>
				</div>
			</div>,

			{label: `${selected.size} Selected`, toggled: this.state.showSelected, group: "selected", handler: this.showSelectedHandler, disabled: selected.size === 0,
				detail: this.state.showSelected ? "Click to show all records" : "Click to show only records you have selected"},
			{label: "Purge", group: "selectable",
                disabled: user.read_only || selected.size === 0,
                handler: this.purgeHandler}
		];

		return (
			<div>
				<HomeHeader type="upgrades" title="Upgrades" icon={UPGRADE_ICON} actions={actions}
							count={this.state.itemCount}/>
				<UpgradeList onFetch={this.fetchData} refetchOn="upgrades" onFilter={this.filterHandler} filters={filterColumns}
							 onSelect={this.selectionHandler} selected={selected} showSelected={this.state.showSelected}/>
				{this.state.showStats ?
					<AnalysisWindow title={this.state.title} upgradeStats={this.state.upgradeStats}
					onClose={this.closeAnalysisWindow}/> : ""
				}
			</div>
		);
	}

	// Handlers
	fetchData() {
		return upgradeService.requestAll();
	}
	
	showStatsMenu(){
		if(this.state.selected.size === 0){
			this.setState({isOpen: false});
		} else {
			this.setState({isOpen: !this.state.isOpen});
		}
	}

	showSelectedHandler() {
		this.setState({showSelected: !this.state.showSelected});
	}
	
	selectionHandler(selected) {
		let showSelected = this.state.showSelected;
		if (selected.size === 0) {
			showSelected = false;
			this.setState({isOpen: false});
		}
		this.setState({selected, showSelected});

	}

	filterHandler(filtered, filterColumns, itemCount) {
		this.setState({itemCount, filterColumns});
	}
	
	applySavedFilter(filterColumns) {
		this.setState({filterColumns});
	}
	
	purgeHandler() {
		const msg = this.state.selected.size === 1 ?
			`Are you sure you want to purge this upgrade history?` :
			`Are you sure you want to purge the history of these ${this.state.selected.size} upgrades?`;
		if (window.confirm(msg)) {
			let please = window.prompt(`Really?  Type the magic word`);
			if (please && please.toLowerCase() === 'please') {
				upgradeService.purge(Array.from(this.state.selected.keys())).then(() => {
					this.state.selected.clear();
					this.setState({showSelected: false});
				})
				.catch(e => notifier.error(e.message | e, "Fail"));
			}
		}
	}

	getSumUpgradeResults(){
		this.openAnalysisWindow("Job");
	}

	getAggregateUpgradeResults(){
		this.openAnalysisWindow("Aggregate");
	}

	openAnalysisWindow(resultType) {

	    let tittle = "Upgrade Job Results"
        if(resultType === "Aggregate") {
            tittle = "Org Upgrade Results"
        }
		let p =  new Promise((resolve, reject) => {
			upgradeStats.requestStatsByid(Array.from(this.state.selected.keys()), resultType).then(data => {
				this.setState({showSelected: false});
				resolve(data);
			}).catch(reject);
		});
		p.then(upgradeStats => {
			let stats = this.groupStatsbyPackage(upgradeStats , resultType);
			this.setState({showStats: true, upgradeStats: stats, title: tittle});
		});
	}

	closeAnalysisWindow() {
		this.setState({showStats: null});
	}

    groupStatsbyPackage(upgradeStats, resultType){
		let items = [];
		for (const [index, value] of upgradeStats.entries()) {
			const data = {};
			data.package = value.name
            if(resultType === "Job"){
                data.stats = value.status.concat(' Upgrades:\t' + value.count)
            } else {
                data.stats = value.status.concat(' Orgs:\t' + value.count)
            }


			let elemIndex = items.findIndex( obj => obj.package === data.package)
			if(elemIndex == -1){
				items.push(data)
			} else {
				items[elemIndex].stats = items[elemIndex].stats.concat("\n" + data.stats)
			}
		}
		return items;
	}
}